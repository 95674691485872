import React from 'react';
import { Link } from 'gatsby';
import Button from '../../button/button';
import Icon from '../../icons';

const CardMicro = ({ data, styles, content }) => {
    return (
        <div className={`${styles.card} ${styles.cardMicro}`} style={{backgroundImage: `url(${`${data.featureImage.asset.url}?w=330&h=190&fit=crop&crop=center`})`}}>
            {/* {console.log(data)} */}
            <Link to={data.slug && `/start/${data._type}/${data.slug.current}/`} className={styles.link}>&nbsp;</Link>
            {content && (
                <div className={styles.cardContent}>
                    <span className={styles.smallTitle}>{data.title}</span>
                    <Button size="small">
                        {data._type === 'written' && <>Read</>}
                        {(data._type === 'video' || data._type === 'audio') && <><Icon symbol="play" /></>}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default CardMicro