import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Container from '../container'
import Card from '../hub/card/card'
import { Link } from 'gatsby'
import ThemeToogle from '../theme-toggle/themeToggle'
import Icon from '../icons'

import * as styles from './footer.module.css'

const Footer = ({showDarkModeToggle}) => {
    // const query = useStaticQuery(graphql`
    //     query FooterQuery {
    //         menus: sanityMenus(_id: { regex: "/(drafts.|)menus/" }) {
    //             footerMenu {
    //                 blank
    //                 label
    //                 path
    //             }
    //         }
    //     }
    // `);

    // const footerNavItems = query.menus.footerMenu;

    const query = useStaticQuery(graphql`
        query FooterQuery {
            feature: sanityVideo(highlighted: {eq: true}) {
                _type
                featureImage {
                  asset {
                    _id
                    url
                  }
                }
                series {
                    title
                }
                title
                videoId
                slug {
                  current
                }
            }
        }
    `);

    const footerFeature = query.feature;

    return (
        <footer className={`${styles.footer}`}>
            <Container size='large'>
                <div className={styles.top}>
                    <div className={styles.logo}>
                        <Icon symbol="logoFull" />
                    </div>
                    {showDarkModeToggle && (
                        <div className={styles.themeToggle}>
                            Dark Mode <ThemeToogle />
                        </div>
                    )}
                </div>

                <div className={styles.middle}>
                    <div className={styles.menu}>

                        <div className={styles.menuBlock}>
                            {/* {footerNavItems &&
                                footerNavItems.map((link, index) => {
                                if (link.blank) {
                                    return (
                                    <a key={index} href={link.path} target="_blank">{link.label}</a>
                                    )
                                } else if (link.path.startsWith('http')) {
                                    return (
                                    <a key={index} href={link.path}>{link.label}</a>
                                    )
                                } else {
                                    return (
                                    <Link key={index} to={link.path}>{link.label}</Link>
                                    )
                                }
                            })} */}
                            <span className={styles.microTitle}>About</span>
                            <Link to="/companies/">Companies</Link>
                            <Link to="/our-people/">Team</Link>
                            <Link to="/start/">Content Hub</Link>
                            <a href="https://careers.redpoint.com/jobs" target="_blank" rel="noopener noreferrer">Careers</a>
                        </div>

                        <div className={styles.menuBlock}>
                            <span className={styles.microTitle}>Social</span>
                            <a href="https://www.tiktok.com/@redpoint?lang=en" target="_blank" rel="noopener noreferrer">TikTok</a>
                            <a href="https://www.youtube.com/channel/UCsa9FJqjpUmMF5TZjOX2K2g/featured" target="_blank" rel="noopener noreferrer">YouTube</a>
                            <a href="https://www.instagram.com/redpoint/?hl=en" target="_blank" rel="noopener noreferrer">Instagram</a>
                            <a href="https://www.facebook.com/RedpointVC/" target="_blank" rel="noopener noreferrer">Facebook</a>
                            <a href="https://open.spotify.com/show/5WqBqDb4br3LlyVrdqOYYb" target="_blank" rel="noopener noreferrer">Spotify</a>
                            <a href="https://podcasts.apple.com/us/podcast/cartoon-avatars/id1606770839" target="_blank" rel="noopener noreferrer">Apple</a>
                        </div>

                        <div className={styles.menuBlock}>
                            <span className={styles.microTitle}>Content Types</span>
                            <Link to="/start/">Featured</Link>
                            <Link to="/start/video/">Video</Link>
                            <Link to="/start/audio/">Audio</Link>
                            <Link to="/start/written/">Written</Link>
                        </div>

                    </div>

                    {footerFeature && (
                        <div className={styles.footerFeature}>
                            <Card type='micro' data={footerFeature} content={false} />
                            <span className={styles.overline}>Resources & Content</span>
                            <span className={styles.subTitle}>
                                Watch the new Unsupervised Learning with Adobe's CPO.
                            </span>
                        </div>
                    )}
                </div>

                <div className={styles.bottom}>
                    <div className={styles.copyright}>
                        &copy; {new Date().getFullYear()} - Redpoint Ventures, all rights reserved
                    </div>

                    <div className={styles.policies}>
                        <Link to="/terms-of-use/">Terms of Use</Link>
                        <span className={styles.separator}>|</span>
                        <Link to="/cookie-policy/">Cookie Policy</Link>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
