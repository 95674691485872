import React from 'react'

import styles from './container.module.css'

const Container = (props) => {
  const size = 'size' in props ? props.size : 'normal';

  return <div className={`${styles.root} ${styles[size]} ${props.class}`}>{props.children}</div>
}

export default Container
