import React from 'react'

const YouTube = () => (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_519_3662)">
            <path d="M12.7325 1.40331C12.5811 0.847934 12.1421 0.416529 11.5819 0.267769C10.5675 0 6.5 0 6.5 0C6.5 0 2.43245 0 1.41809 0.267769C0.862966 0.416529 0.418866 0.852893 0.272516 1.40331C0 2.40496 0 4.49752 0 4.49752C0 4.49752 0 6.59008 0.272516 7.59174C0.423913 8.14711 0.862966 8.57851 1.42314 8.72727C2.4375 8.99504 6.50505 8.99504 6.50505 8.99504C6.50505 8.99504 10.5726 8.99504 11.587 8.72727C12.1471 8.57851 12.5862 8.14215 12.7376 7.59174C13.0101 6.59008 13.0101 4.49752 13.0101 4.49752C13.0101 4.49752 13.0101 2.40496 12.7376 1.40331H12.7325ZM5.17275 6.40165V2.60331L8.5691 4.50248L5.17275 6.40165Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_519_3662">
                <rect width="13" height="9" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)

export default YouTube
