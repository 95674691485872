import React from 'react'

const CloseIcon = () => (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="17.793" width="24" height="2.11765" transform="rotate(-45 0 17.793)" fill="currentColor"/>
        <rect x="1.49805" y="1" width="24" height="2.11765" transform="rotate(45 1.49805 1)" fill="currentColor"/>
    </svg>
)

export default CloseIcon
