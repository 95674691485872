import { Link } from 'gatsby'
import React from 'react'
import Icon from './icons'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockContent from './block-content'
import Container from './container'

import styles from './announcement.module.css'

class Announcement extends React.Component {
    constructor(props) {
      super(props)
      this.state = { showModal: false }
      this.handleClick = this.handleClick.bind(this)
    }
  
    componentDidMount() {
        const preClosed = sessionStorage.getItem('rpAnnounce');

        if (!preClosed) {
            setTimeout(() => {
                this.setState({ showModal: !this.state.showModal })
            }, 3000);
        }
    }

    handleClick() {
        this.setState({ showModal: !this.state.showModal })
        sessionStorage.setItem('rpAnnounce', true);
    }
  
    render() {
        if (this.props.data.enabled) {
            return (
                <div className={`${styles.root} ${this.state.showModal ? styles.showModal : styles.hideModal}`} style={{backgroundColor: this.props.data.backgroundColor.hex, color: this.props.data.textColor.hex}}>
                    <Container>
                        <div className={styles.image}>
                        {this.props.data.image && this.props.data.image.asset && (
                            <img src={imageUrlFor(buildImageObj(this.props.data.image)).url()} />
                        )}
                        </div>
                        <div className={styles.text}>
                            {this.props.raw.content && <BlockContent blocks={this.props.raw.content || []} />}
                        </div>
                        <div className={styles.close} onClick={this.handleClick}>
                            <Icon symbol='close' />
                        </div>
                    </Container>
                </div>
            )
        } else {
            return '';
        }
    }
}

export default Announcement
