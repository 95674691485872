import React from 'react';

const CardSocial = ({ data, styles, short }) => {
    return (
        <div className={`${styles.card} ${styles.cardSocial}`}>
            <div className={`${styles.cardAsset} ${short ? styles.short : ''}`}>
                <a href={data.url} className={styles.cardLink} target="_blank">
                    <img src={(data.featureImage && `${data.featureImage.asset.url}?w=237&h=431&fit=crop&crop=center`) || "https://via.placeholder.com/450x500?text=Placeholder"} alt={data.title} />
                </a>
            </div>
            <div className={styles.cardContent}>
                {data.platform && (<span className={styles.overline}>{data.platform}</span>)}
                <a href={data.url} className={styles.title} target="_blank">
                    {data.title}
                </a>
            </div>
        </div>
    )
}

export default CardSocial