import React from 'react'

const Spotify = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_519_3648)">
            <path d="M6.79762 0.163269C3.13333 0.163269 0.16333 3.13327 0.16333 6.79755C0.16333 10.4618 3.13333 13.4318 6.79762 13.4318C10.4619 13.4318 13.4319 10.4618 13.4319 6.79755C13.4319 3.13327 10.4619 0.163269 6.79762 0.163269ZM9.85027 9.75102C9.71802 9.94939 9.4866 10.0155 9.28823 9.88327C7.72884 8.91898 5.77272 8.72062 3.44741 9.25511C3.21598 9.32123 3.01762 9.15592 2.95149 8.95755C2.88537 8.72613 3.05068 8.52776 3.24904 8.46164C5.77272 7.8996 7.96027 8.13102 9.68496 9.18898C9.91639 9.28817 9.94945 9.55266 9.85027 9.75102ZM10.6492 7.92715C10.4839 8.15858 10.1864 8.25776 9.95496 8.09245C8.16415 6.99592 5.44211 6.66531 3.35374 7.32653C3.08925 7.39266 2.7917 7.26041 2.72557 6.99592C2.65945 6.73143 2.7917 6.43388 3.05619 6.36776C5.48068 5.64041 8.46721 6.00409 10.5225 7.26592C10.7209 7.36511 10.8201 7.69572 10.6548 7.92715H10.6492ZM10.7154 6.07021C8.59394 4.80837 5.03986 4.67613 3.01762 5.30429C2.687 5.40347 2.35639 5.20511 2.2517 4.90755C2.15251 4.57694 2.35088 4.24633 2.64843 4.14164C5.0068 3.44735 8.88598 3.5796 11.3435 5.0398C11.6411 5.20511 11.7403 5.60184 11.575 5.9049C11.4097 6.13633 11.0129 6.23551 10.7099 6.07021H10.7154Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_519_3648">
                <rect width="13.2741" height="13.2741" fill="white" transform="translate(0.16333 0.163269)"/>
            </clipPath>
        </defs>
    </svg>

)

export default Spotify
