import { Link } from 'gatsby'
import React from 'react'
import Icon from './icons'
import { cn } from '../lib/helpers'

import styles from './header.module.css'

const Header = ({ onHideNav, onShowNav, showNav, siteTitle, navItems, page }) => (
  <div className={cn(styles.root, showNav && styles.navShown, styles[page])}>
    <div className={styles.wrapper}>
      <h1 className={styles.branding}>
        <Link to='/'>{siteTitle}</Link>
      </h1>

      <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol='hamburger' />
        <Icon symbol='close' />
      </button>

      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          {navItems &&
            navItems.map((link, index) => {
              if (link.blank) {
                return (
                  <li key={index}><a href={link.path} target="_blank">{link.label}</a></li>
                )
              } else if (link.path.startsWith('http')) {
                return (
                  <li key={index}><a href={link.path}>{link.label}</a></li>
                )
              } else {
                return (
                  <li key={index}><Link to={link.path}>{link.label}</Link></li>
                )
              }
            })}
        </ul>
      </nav>
    </div>
  </div>
)

export default Header
