import React from 'react';
import Simple from './cardSimple'
import Detail from './cardDetail'
import Visual from './cardVisual'
import Micro from './cardMicro'
import Audio from './cardAudio'
import Social from './cardSocial'

import * as styles from './card.module.css'

const Card = ({ type, data, short, display = true, content = true }) => {
    switch (type) {
        case 'social':
            return <Social data={data} styles={styles} short={short} />

        case 'simple':
            return <Simple data={data} styles={styles} short={short} display={display} />
            
        case 'detail':
            return <Detail data={data} styles={styles} />

        case 'visual':
            return <Visual data={data} styles={styles} display={display} />

        case 'micro':
            return <Micro data={data} styles={styles} content={content} />

        case 'audio':
            return <Audio data={data} styles={styles} />

        default:
            return null
    }
}

export default Card