import React from 'react';

const PlayCircle = () => {
    return (
        <svg width="116" height="116" viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.9">
                <path d="M77.5 55.9019C79.5 57.0566 79.5 59.9434 77.5 61.0981L48.25 77.9856C46.25 79.1403 43.75 77.6969 43.75 75.3875L43.75 41.6125C43.75 39.3031 46.25 37.8597 48.25 39.0144L77.5 55.9019Z" fill="currentColor"/>
                <circle cx="58" cy="58" r="57.5" stroke="currentColor"/>
            </g>
        </svg>

    )
}

export default PlayCircle