import React from 'react'
import { Link } from 'gatsby';

import * as styles from './button.module.css'

const Button = ({ children, element, onClick, level = 'primary', size = 'normal', link = false, version = '2' }) => {
    if (element === 'buttonSubmit') {
        return (
            <button type="submit" className={`${styles.button} ${styles[level]} ${styles[size]} ${styles[`version${version}`]}`} onClick={onClick}>{children}</button>
        )
    } else if (link) {
        if (link.startsWith('http')) {
            return (
                <a className={`${styles.button} ${styles[level]} ${styles[size]} ${styles[`version${version}`]}`} href={link} target="_blank" rel="noopener noreferrer" onClick={onClick}>
                    {children}
                </a>
            )
        } else {
            return (
                <Link className={`${styles.button} ${styles[level]} ${styles[size]} ${styles[`version${version}`]}`} to={link}>
                    {children}
                </Link>
            )
        }
    } else {
        return (
            <a className={`${styles.button} ${styles[level]} ${styles[size]} ${styles[`version${version}`]}`} onClick={onClick}>{children}</a>
        )
    }
}

export default Button
