import React from 'react';
import { Link } from 'gatsby';
import Icon from '../../icons';
import Button from '../../button/button';

const CardVisual = ({ data, styles, display = true }) => {
  return (
        <div className={`${styles.card} ${styles.cardVisual} ${!display ? styles.hide : ''}`} style={{backgroundImage: `url(${(data.featureImage && data.featureImage.asset) ? `${data.featureImage.asset.url}?w=1150&h=650&fit=crop&crop=center` : ''})`}}>
            {/* {console.log(data)} */}
            <Link to={data.slug && `/start/${data._type}/${data.slug.current}/`} className={styles.link}>&nbsp;</Link>
            <div className={`${styles.cardAsset}`}>
                <Link to={data.slug && `/start/${data._type}/${data.slug.current}/`} className={styles.cardLink}>
                    <img src={(data.featureImage && `${data.featureImage.asset.url}?w=1150&h=650&fit=crop&crop=center`) || "https://via.placeholder.com/450x500?text=Placeholder"} alt={data.title} />
                    {data._type === 'video' && (
                        <Icon symbol="playCircle" />
                    )}
                </Link>
            </div>
            <div className={styles.cardContent}>
                {!data.hideText && (
                    <>
                      {data.series?.title && (<span className={styles.overline}>Series: {data.series.title}</span>)}
                    <h3>{data.title}</h3>
                    <div className={styles.tags}>
                        {data.tags && data.tags.map((tag, tagIndex) => (
                            <Link key={tagIndex} to={`/start/tag/${tag.slug.current}/`} className={styles.tag}>
                                {tag.title}
                            </Link>
                        ))}
                    </div>
                    </>
                )}
                <Button link={data.slug && `/start/${data._type}/${data.slug.current}/`}>
                    {data._type === 'written' && <>Read</>}
                    {data._type === 'video' && <>Play <Icon symbol="play" /></>}
                </Button>
            </div>
        </div>
    )
}

export default CardVisual
