import { graphql, StaticQuery } from 'gatsby'
import React, { useState, useContext } from 'react'
import Helmet from 'react-helmet'
import { themeContext } from '../lib/provider'
import Layout from '../components/layout'

import styles from '../components/globals.module.css'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      socialLinkedin
      socialInstagram
      socialMedium
      socialTwitter
      _rawAnnouncement
      announcement {
        image {
          asset {
            _id
            url
          }
        }
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        enabled
      }
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      name
      address1
      address2
      zipCode
      city
      country
    }
    menus: sanityMenus(_id: { regex: "/(drafts.|)menus/" }) {
      headerMenu {
        blank
        label
        path
      }
      footerMenu {
        blank
        label
        path
      }
    }
  }
`

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false);
  const themeCtx = useContext(themeContext);

  const handleShowNav = () => {
    setShowNav(true)
    window.scrollTo(0,0);
  }

  const handleHideNav = () => {
    setShowNav(false)
  }
  
  return (
    <>
      <Helmet
          bodyAttributes={{
              class: `${showNav && styles.stick} ${('version' in props && props.version === '2') && themeCtx ? (themeCtx.darkMode ? 'dark v2' : 'light v2') : 'light v1'}`
          }}
      />
      <StaticQuery
        query={query}
        render={data => {
          if (!data.site) {
            throw new Error(
              'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
            )
          }
          if (!data.companyInfo) {
            throw new Error(
              'Missing "Company info". Open the studio at http://localhost:3333 and add "Company info" data'
            )
          }
          return (
            <Layout
              {...props}
              showNav={showNav}
              rawAnnouncement={data.site._rawAnnouncement}
              announcement={data.site.announcement}
              headerNavItems={data.menus.headerMenu}
              footerNavItems={data.menus.footerMenu}
              siteData={data.site}
              companyInfo={data.companyInfo}
              siteTitle={data.companyInfo.name}
              onHideNav={handleHideNav}
              onShowNav={handleShowNav}
              hideContactBar={props.hideContactBar ? true : false}
              hideAnnouncements={props.hideAnnouncements ? true : false}
              version={props.version}
            />
          )
        }}
      />
    </>
  )
}

export default LayoutContainer
