import React from 'react';
import { Link } from 'gatsby';
import Button from '../../button/button';
import Icon from '../../icons';

const CardSimple = ({ data, styles, short, display = true }) => {
    return (
        <div className={`${styles.card} ${styles.cardSimple} ${!display ? styles.hide : ''}`}>
            {/* {console.log(data)} */}
            <div className={`${styles.cardAsset} ${short ? styles.short : ''}`}>
                <Link to={data.slug && `/start/${data._type}/${data.slug.current}/`} className={styles.cardLink}>
                    <img src={(data.featureImage && `${data.featureImage.asset.url}?w=450&h=473&fit=crop&crop=center`) || "https://via.placeholder.com/450x500?text=Placeholder"} alt={data.title} />
                    {data._type === 'video' && (
                        <Icon symbol="playCircle" />
                    )}
                </Link>
            </div>
            <div className={styles.cardContent}>
                <div className={styles.tags}>
                    {data.tags && data.tags.map((tag, tagIndex) => (
                        <Link key={tagIndex} to={`/start/tag/${tag.slug.current}/`} className={styles.tag}>
                            {tag.title}
                        </Link>
                    ))}
                </div>
                {data.series && (<span className={styles.overline}>Series: {data.series.title}</span>)}
                <Link to={data.slug && `/start/${data._type}/${data.slug.current}/`} className={styles.title}>
                    {data.title}
                </Link>
                {data._type === 'audio' && (
                    <div className={`${styles.listenOn} ${styles.icons}`}>Listen on <Button level="secondary" size="small" link={data.spotifyUrl}><Icon symbol="spotify" /></Button> <Button level="secondary" size="small" link={data.appleUrl}><Icon symbol="apple" /></Button> <Button level="secondary" size="small" link={data.youTubeUrl}><Icon symbol="youtube" /></Button></div>
                )}
            </div>
        </div>
    )
}

export default CardSimple