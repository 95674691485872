import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder({
  "projectId": "22xmfoma",
  "dataset": "production"
})

export function imageUrlFor (source) {
  return builder.image(source)
}
