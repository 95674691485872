import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import favicon from '../img/favicon.png'

const detailsQuery = graphql`
  query SEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      metaDescription
      metaKeywords
      metaImage {
        asset {
          _id
          url
        }
      }
    }
  }
`

function SEO ({ description, lang, meta, keywords = [], title, imageFB, imageTW }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        if (!data.site) {
          return
        }
        // console.log(imageFB, imageTW);
        const metaDescription = description || data.site.metaDescription
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={title === data.site.title ? '%s' : `%s | ${data.site.title}`}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:title',
                content: title
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                property: 'og:image',
                content: typeof imageFB === 'string' ? imageFB : imageUrlFor(buildImageObj(imageFB || data.site.metaImage)).url()
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image'
              },
              {
                name: 'twitter:creator',
                content: ''
              },
              {
                name: 'twitter:title',
                content: title
              },
              {
                name: 'twitter:description',
                content: metaDescription
              },
              {
                name: 'twitter:image',
                content: typeof imageTW === 'string' ? imageTW : (typeof imageFB === 'string' ? imageFB : imageUrlFor(buildImageObj(imageTW || imageFB || data.site.metaImage)).url())
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: Array.isArray(keywords) ? keywords.join(', ') : keywords
                  }
                  : []
              )
              .concat(meta)}
          >
            <link rel="icon" type="image/png" href={favicon}></link>
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=PT+Serif:wght@700&display=swap" rel="stylesheet"></link>
            <link rel="stylesheet" href="https://use.typekit.net/ykv6skl.css"></link>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO
