import React from 'react';

const Apple = () => {
    return (
        <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_519_3656)">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.06944 1.21844C6.95609 0.00595133 8.18853 -0.000488281 8.18853 -0.000488281C8.18853 -0.000488281 8.37184 1.13956 7.49118 2.23752C6.55089 3.41045 5.48181 3.21818 5.48181 3.21818C5.48181 3.21818 5.2812 2.29639 6.06944 1.21844Z" fill="currentColor"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.59507 3.88049C6.05125 3.88049 6.89756 3.22986 7.99944 3.22986C9.89599 3.22986 10.6421 4.63025 10.6421 4.63025C10.6421 4.63025 9.1829 5.40416 9.1829 7.28269C9.1829 9.40156 11.0005 10.1318 11.0005 10.1318C11.0005 10.1318 9.72952 13.8428 8.01319 13.8428C7.22473 13.8428 6.61184 13.2909 5.78127 13.2909C4.93474 13.2909 4.09464 13.8626 3.54758 13.8626C1.9802 13.8626 0.000976562 10.3424 0.000976562 7.51268C0.000976562 4.72983 1.67719 3.26827 3.24922 3.26827C4.2702 3.26896 5.06308 3.88049 5.59507 3.88049Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_519_3656">
                    <rect width="11" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default Apple