import React from 'react'

const HamburgerIcon = () => (
  <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="2.11765" fill="currentColor"/>
    <rect y="7.76562" width="24" height="2.11765" fill="currentColor"/>
    <rect y="15.5312" width="24" height="2.11765" fill="currentColor"/>
  </svg>
)

export default HamburgerIcon
