import React, { useContext } from 'react';
import { themeContext } from '../../lib/provider'

import * as styles from './themeToggle.module.css';

const ThemeToogle = () => {
    const themeCtx = useContext(themeContext);

    return (
        <div className={`${styles.pill} ${themeCtx ? (themeCtx.darkMode ? styles.active : '') : styles.active}`} role="presentation" onClick={() => themeCtx.changeThemeMode()}>
            <div className={styles.handle}></div>
        </div>
    )
}

export default ThemeToogle;